import { Button, Divider, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Link as MuiLink } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import LevavLogo from '../../sources/Logo/LEVAV Logo.png';
import MindverseLogo from '../../sources/Logo/Mindverse Logo.png';
import Link from '@mui/material/Link'
import GooglePlayLogo from '../../sources/Icon/GooglePlay.png';
import AppStoreLogo from '../../sources/Icon/AppStore.png';
import FacebookLogo from '../../sources/Icon/FacebookIcon.png';
import InstagramLogo from '../../sources/Icon/InstagramIcon.png';
import TwitterLogo from '../../sources/Icon/TwitterIcon.png';

export default function Footer() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box sx={{ px: isMobile ? 2 : 4, py: 4 }}>
      {isMobile ? (
          <Grid container direction="column" alignItems="center">
          {/* Logo Section */}
          <Grid item container spacing={2} justifyContent="center" sx={{ mb: 4 }}>
            <Grid item xs={6} md={3} sx={{ textAlign: 'center' }}>
              <Box component="img"
                src={LevavLogo}
                alt="LEVAV"
                sx={{
                  width: '100%',
                  maxWidth: '200px',
                  height: 'auto'
                }}
              />
            </Grid>
            <Grid item xs={10} md={3} sx={{ textAlign: 'center' }}>
              <Box component="img"
                src={MindverseLogo}
                alt="Mindverse"
                sx={{
                  width: '100%',
                  maxWidth: '200px',
                  height: 'auto'
                }}
              />
            </Grid>
          </Grid>

          {/* Download Section */}
          <Grid item container direction="column" alignItems="center" sx={{ mb: 4 }}>
            <Typography 
              fontFamily="Lava Devanagari"
              color="#D19676"
              fontSize={isMobile ? 18 : 20}
              fontWeight={500}
              sx={{ mb: 2 }}
            >
              {t('Footer.text1')}
            </Typography>

            <Grid container justifyContent="center" spacing={2}>
              <Grid item>
                <Link href="https://www.apple.com/hk/app-store/">
                  <Box component="img"
                    src={AppStoreLogo}
                    alt="AppStore"
                    sx={{
                      height: isMobile ? '50px' : '68px',
                      width: 'auto'
                    }}
                  />
                </Link>
              </Grid>
              <Grid item>
                <Link href='https://play.google.com/store/games'>
                  <Box component="img"
                    src={GooglePlayLogo}
                    alt="GooglePlay"
                    sx={{
                      height: isMobile ? '50px' : '68px',
                      width: 'auto'
                    }}
                  />
                </Link>
              </Grid>
            </Grid>
          </Grid>

          {/* Social Media Section */}
          <Grid item container direction="column" alignItems="center" sx={{ mb: 4 }}>
            <Typography 
              fontFamily="Lava Devanagari"
              color="#D19676"
              fontSize={isMobile ? 18 : 20}
              fontWeight={500}
              sx={{ mb: 2 }}
            >
              {t('Footer.text2')}
            </Typography>

            <Grid container justifyContent="center" spacing={2}>
              {[
                { icon: FacebookLogo, link: 'https://www.facebook.com/' },
                { icon: InstagramLogo, link: 'https://www.instagram.com' },
                { icon: TwitterLogo, link: 'https://twitter.com' }
              ].map((social, index) => (
                <Grid item key={index}>
                  <Link href={social.link}>
                    <Box component="img"
                      src={social.icon}
                      alt={social.link.split('.com')[0].split('www.')[1]}
                      sx={{
                        height: isMobile ? '40px' : '50px',
                        width: 'auto'
                      }}
                    />
                  </Link>
                </Grid>
              ))}
            </Grid>
          </Grid>

          {/* Footer Text Section */}
          <Grid item container direction={isMobile ? 'column' : 'row'} 
            justifyContent="space-between"
            alignItems="center"
            spacing={isMobile ? 2 : 0}
          >
            <Grid item xs={12} md={6} sx={{ textAlign: isMobile ? 'center' : 'left' }}>
              <Typography fontFamily="HelveticaNeue" fontSize="14px">
                © 2024 Kadima Technology Limited All rights reserved.
              </Typography>
            </Grid>

            <Grid item xs={12} md={6} sx={{ textAlign: isMobile ? 'center' : 'right' }}>
              <Box display="flex" 
                justifyContent={isMobile ? 'center' : 'flex-end'}
                gap={2}
              >
                <MuiLink 
                  onClick={() => navigate(`/`)} 
                  sx={{ 
                    textDecoration: "none", 
                    color: "black",
                    cursor: 'pointer',
                    fontSize: "14px",
                    fontFamily: "HelveticaNeue"
                  }}
                >
                  {t('Footer.TAC')}
                </MuiLink>
                <Divider orientation="vertical" flexItem />
                <MuiLink 
                  onClick={() => navigate(`/PrivacyPolicy`)} 
                  sx={{ 
                    textDecoration: "none", 
                    color: "black",
                    cursor: 'pointer',
                    fontSize: "14px",
                    fontFamily: "HelveticaNeue"
                  }}
                >
                  {t('Footer.PP')}
                </MuiLink>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid 
        container 
        direction="column"
        sx={{
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        {/* Main Content Section */}
        <Grid 
          item 
          container 
          xs={12} 
          direction="column" 
          sx={{ 
            justifyContent: "flex-start", 
            alignItems: "flex-start" 
          }}
        >
          {/* Logos */}
          <Grid 
            item 
            container 
            direction="row" 
            spacing={2}
            py={7}
            sx={{
              justifyContent: "flex-start", 
              alignItems: "center"
            }}
          >
            <Grid item xs={6} md={2}>
              <img
                src={LevavLogo}
                alt="LEVAV"
                style={{
                  width: '100%',
                  objectFit: 'contain'
                }}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <img
                src={MindverseLogo}
                alt="Mindverse"
                style={{
                  width: '100%',
                  objectFit: 'contain'
                }}
              />
            </Grid>
          </Grid>

          {/* App Download Section */}
          <Grid item py={3}>
            <Typography 
              fontFamily="Lava Devanagari"
              color="#D19676"
              fontSize={20}
              fontWeight={500}
            >
              {t('Footer.text1')}
            </Typography>
          </Grid>

          <Grid 
            item 
            container 
            direction="row" 
            spacing={0} 
            py={3}
          >
            <Grid item xs={6} md={2.5}>
              <Link href="https://www.apple.com/hk/app-store/">
                <img
                  src={AppStoreLogo}
                  alt="AppStore"
                  style={{
                    width: 'auto',
                    height: '68px'
                  }}
                />
              </Link>
            </Grid>
            <Grid item xs={6} md={2.5}>
              <Link href='https://play.google.com/store/games'>
                <img
                  src={GooglePlayLogo}
                  alt="GooglePlay"
                  style={{
                    width: 'auto',
                    height: '68px'
                  }}
                />
              </Link>
            </Grid>
          </Grid>

          {/* Social Media Section */}
          <Grid item py={3}>
            <Typography 
              fontFamily="Lava Devanagari"
              color="#D19676"
              fontSize={20}
              fontWeight={500}
            >
              {t('Footer.text2')}
            </Typography>
          </Grid>

          <Grid 
            item 
            container 
            direction="row" 
            spacing={2} 
            py={3}
            sx={{
              justifyContent: "flex-start",
              alignItems: "flex-start"
            }}
          >
            {[
              { icon: FacebookLogo, link: 'https://www.facebook.com/' },
              { icon: InstagramLogo, link: 'https://www.instagram.com' },
              { icon: TwitterLogo, link: 'https://twitter.com' }
            ].map((social, index) => (
              <Grid item xs={4} md={1} key={index}>
                <Link href={social.link}>
                  <img
                    src={social.icon}
                    alt={social.link.split('.com')[0].split('www.')[1]}
                    style={{
                      width: 'auto',
                      height: '50px'
                    }}
                  />
                </Link>
              </Grid>
            ))}
          </Grid>
        </Grid>

        {/* Footer Bottom Section */}
        <Grid 
          item 
          container 
          direction="row"
          sx={{
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          {/* Copyright */}
          <Grid 
            item 
            xs={12} 
            md={4} 
            mb={2} 
            sx={{
              display: "flex",
              justifyContent: { 
                xs: "center", 
                md: "flex-start" 
              }
            }}
          >
            <Typography 
              fontFamily="HelveticaNeue"
              fontSize="14px"
              textAlign="left"
            >
              © 2024 Kadima Technology Limited All rights reserved.
            </Typography>
          </Grid>

          {/* Spacers */}
          <Grid container item xs={0} md={4}></Grid>

          {/* Links */}
          <Grid 
            item 
            xs={12} 
            md={4} 
            mb={2}
            sx={{
              display: "flex",
              justifyContent: { 
                xs: "center", 
                md: "flex-end" 
              }
            }}
          >
            <Box 
              display="flex"
              flexDirection="row"
              justifyContent={{ 
                xs: "center", 
                md: "space-between" 
              }}
            >
              <MuiLink 
                onClick={() => navigate(`/`)} 
                sx={{ 
                  textDecoration: "none", 
                  color: "black", 
                  cursor: 'pointer',
                  mr: 2
                }}
              >
                <Typography 
                  fontFamily="HelveticaNeue"
                  fontSize="14px"
                >
                  {t('Footer.TAC')}
                </Typography>
              </MuiLink>
              <Divider orientation="vertical" flexItem />
              <MuiLink 
                onClick={() => navigate(`/PrivacyPolicy`)} 
                sx={{ 
                  textDecoration: "none", 
                  color: "black", 
                  cursor: 'pointer',
                  ml: 2
                }}
              >
                <Typography 
                  fontFamily="HelveticaNeue"
                  fontSize="14px"
                >
                  {t('Footer.PP')}
                </Typography>
              </MuiLink>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      )}
    
    </Box>
  );
}