import React, { useRef, useEffect } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import Container from '@mui/material/Container'

import Introduction from '../sections/Introduction.js';
import Levav from '../sections/Levav';
import Features from '../sections/Features';
import Mindverse from '../sections/Mindverse';
import Gallery_CustomizedService from '../sections/Gallery_CustomizedService.js';
import Gallery_StudentWorks from '../sections/Gallery_StudentWorks.js';
import Partners from '../sections/Partners.js';
import ContactUs from '../sections/ContactUs';
import Footer from '../sections/Footer';
import background from '../../sources/Background/header.png';
import brush from '../../sources/Graph/Brush_purple.png';


function Home() {
  const { t } = useTranslation();

  const location = useLocation();

  const levalRef = useRef(null);
  const mindverseRef = useRef(null);
  const contactRef = useRef(null);
  

  useEffect(() => {
    if (location.hash) {
      const id = location.hash.replace('#', '');
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  

  return (
    <Box
      sx={{
        height: 'auto',
        width: '100%',
        backgroundImage: `url(${background})`,
        backgroundPosition: "center top",
        backgroundSize: { xs: "100% 100%", md: "100% 20%" },
        backgroundRepeat: "no-repeat",
        backgroundColor: "#FADDC6",
        display:"flex",
        flexDirection:"column",
        pt:"xs: 15, md: 20 ",
        margin: '0',
        overflowX: 'hidden'
      }}
    >
    
      <Box sx={{ pt: 8 }}>
        <Box id="introduction" paddingBottom={27} sx={{ width: '100%'}}>
          <Container maxWidth="xl">
            <Introduction />
          </Container>
        </Box>

        <Box ref={levalRef} id="levav" py={7} sx={{ width: '100%', backgroundColor: '#fff5f0' }}>
        
          <Container maxWidth="xl">           
            <Levav />
          </Container>
        </Box>

        <Box id="features" py={7} pb={15} sx={{ width: '100%', backgroundColor: '#fff5f0' }}>
          <Container>
            <Features />
          </Container>         
        </Box>

        <Box ref={mindverseRef} id="mindverse" py={7} pb={7} sx={{ width: '100%', backgroundColor: '#ffebe2' }}>
          <Container maxWidth="xl">
            <Mindverse />
          </Container>          
        </Box>

        <Box id="gallery_CustomizedService" py={7} pb={7} sx={{ width: '100%', backgroundColor: '#ffebe2' }}>
          <Container>
            <Gallery_CustomizedService />
          </Container>          
        </Box>

        <Box id="gallery_StudentWorks" py={7} pb={7} sx={{ width: '100%', backgroundColor: '#ffebe2' }}>
          <Container>
            <Gallery_StudentWorks />
          </Container>
        </Box>
        
        <Box id="partners" py={7} pb={7} sx={{ width: '100%', backgroundColor: '#fff5f0' }}>
          <Container>
            <Partners />
          </Container>         
        </Box>

        <Box ref={contactRef} id="contactUs" py={7} pb={7} sx={{ width: '100%', backgroundColor: '#ffebe2' }}>
          <Container>
            <ContactUs />
          </Container>
        </Box>

        
        
      </Box>
    
    </Box>
  );
}

export default Home;