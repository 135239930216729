import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import partner1 from '../../sources/Graph/partner1.png'
import partner2 from '../../sources/Graph/partner2.png'
import partner3 from '../../sources/Graph/partner3.png'
import partner4 from '../../sources/Graph/partner4.png'
import partner5 from '../../sources/Graph/partner5.png'
import partner6 from '../../sources/Graph/partner6.png'
import partner7 from '../../sources/Graph/partner7.png'
import partner8 from '../../sources/Graph/partner8.png'

function Features() {
  const { t } = useTranslation();

  const partners = [
    { img: partner1},
    { img: partner2},
    { img: partner3},
    { img: partner4},
    { img: partner5},
    { img: partner6},
    { img: partner7},
    { img: partner8},
  ];
  

  return (
    <Box width="100%" align='center'>
      <Typography fontFamily="Noto Sans TC" textAlign="center" fontSize="36px" fontWeight={600} fontStyle="normal" color='#7DC4A2' py={3} sx={{ textShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)', lineHeight: '100%', position: 'relative'}}>
        {t('Partners.title')}
      </Typography>

      <Grid container spacing={2} justifyContent="center">
        {partners.map((partner, index) => (
          <Grid item xs={6} sm={6} md={3} key={index} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Box sx={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', mb: 1 }}>
              <img 
                src={partner.img}
                alt={`partner${index + 1}`}
                style={{ width: '100%', objectFit: 'contain' }}
              />
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default Features;