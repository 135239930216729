import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';


import brush from '../../sources/Graph/Brush_purple.png';
import levav_white_logo from '../../sources/Logo/LEVAV white logo.png'
import mindverse_image from '../../sources/Graph/Mindverse_image.png'
import mindverse_white_logo from '../../sources/Logo/Mindverse white logo.png'
import tag1 from '../../sources/Graph/tag1.png'
import tag2 from '../../sources/Graph/tag2.png'

function Mindverse() {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box width={"100%"} align='center' >

      <Typography 
        fontFamily={"Noto Sans TC"} 
        textAlign={"center"} 
        fontSize={"36px"} 
        fontWeight={600} 
        fontStyle={"normal"} 
        color='#7DC4A2' 
        py={3} 
        sx={{ 
          textShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)', 
          lineHeight: '100%', 
          position: 'relative', 
          backgroundImage: `url(${brush})`, 
          backgroundSize: 'cover', 
          backgroundPosition: 'left', 
          backgroundRepeat: 'no-repeat', 
          backgroundPositionY: 55 
        }}
      >
        {t('Mindverse.title')}
      </Typography>

      {/* Logo and Title Section */}
      <Box 
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 2,
          mb: 4
        }}
      >
        <Box sx={{ width: isMobile ? '60px' : '80px' }}>
          <img
            src={mindverse_white_logo}
            alt="mindverse"
            style={{ 
              width: '100%',
              objectFit: "contain"
            }}
          />
        </Box>
        <Typography 
          fontFamily={"Lava Devanagari"} 
          color={"#4E433C"} 
          fontSize={isMobile ? 32 : 40} 
          fontWeight={500} 
          lineHeight={"128%"}
        >
          Mindverse
        </Typography>
      </Box>

      {isMobile ? (
        // Mobile Layout
        <Box sx={{ px: 3, maxWidth: "100%" }}>
          <Box sx={{ mb: 4 }}>
            <img 
              src={mindverse_image}
              alt="image"
              style={{
                width: "80%",
                objectFit: "contain",
              }}
            />
          </Box>
          
          <Grid container spacing={4} sx={{ justifyContent: "center" }}>
            {/* Left content */}
            <Grid item xs={6} sx={{ textAlign: 'center' }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Box
                  component="img"
                  src={tag1}
                  alt="Mindverse_1"
                  sx={{
                    width: "40px",
                    mb: 2
                  }}
                />
                <Typography fontFamily={"Noto Sans TC"} color={"#4E433C"} fontSize={18} fontWeight={400} lineHeight={"128%"}>
                  {t('Mindverse.text1_1')}
                </Typography>
              </Box>
            </Grid>

            {/* Right content */}
            <Grid item xs={6} sx={{ textAlign: 'center' }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Box
                  component="img"
                  src={tag2}
                  alt="Mindverse_2"
                  sx={{
                    width: "40px",
                    mb: 2
                  }}
                />
                <Typography fontFamily={"Noto Sans TC"} color={"#4E433C"} fontSize={18} fontWeight={400} lineHeight={"128%"}>
                  {t('Mindverse.text2_1')}
                </Typography>
                <Typography fontFamily={"Noto Sans TC"} color={"#4E433C"} fontSize={18} fontWeight={400} lineHeight={"128%"}>
                  {t('Mindverse.text2_2')}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      ) : (
        // Desktop Layout
        <Box
          sx={{
            position: "relative",
            width: "100%",
            maxWidth: "1200px",
            margin: "0 auto",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            px: 4,
            mb: 8
          }}
        >
          {/* Content Container */}
          <Grid container spacing={4} sx={{ justifyContent: "center", alignItems: "center" }}>
            {/* Left Text Block */}
            <Grid item xs={4}>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', pr: 4 }}>
                <Box
                  component="img"
                  src={tag1}
                  alt="Mindverse_1"
                  sx={{
                    width: "60px",
                    mb: 2
                  }}
                />
                <Typography fontFamily={"Noto Sans TC"} color={"#4E433C"} fontSize={24} fontWeight={400} lineHeight={"128%"} textAlign="right">
                  {t('Mindverse.text1_1')}
                </Typography>
              </Box>
            </Grid>

            {/* Center Image */}
            <Grid item xs={4}>
              <Box sx={{ width: "100%", display: 'flex', justifyContent: 'center' }}>
                <img 
                  src={mindverse_image}
                  alt="Mindverse"
                  style={{
                    width: "80%",
                    objectFit: "contain",
                  }}
                />
              </Box>
            </Grid>

            {/* Right Text Block */}
            <Grid item xs={4}>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', pl: 4 }}>
                <Box
                  component="img"
                  src={tag2}
                  alt="Mindverse_2"
                  sx={{
                    width: "60px",
                    mb: 2
                  }}
                />
                <Typography fontFamily={"Noto Sans TC"} color={"#4E433C"} fontSize={24} fontWeight={400} lineHeight={"128%"} textAlign="left">
                  {t('Mindverse.text2_1')}
                </Typography>
                <Typography fontFamily={"Noto Sans TC"} color={"#4E433C"} fontSize={24} fontWeight={400} lineHeight={"128%"} textAlign="left">
                  {t('Mindverse.text2_2')}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  );
}

export default Mindverse;