import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import React from 'react';
import brush from '../../sources/Graph/Brush_green.png';
import image_Intro from '../../sources/Graph/Image_Intro.png';
import Link from '@mui/material/Link';
import GooglePlayLogo from '../../sources/Icon/GooglePlay.png';
import AppStoreLogo from '../../sources/Icon/AppStore.png';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function Introduction() {
    const { t } = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Grid container direction={isMobile ? "column" : "row"} sx={{
            justifyContent: "center",
            alignItems: "center",
            padding: { xs: 2, md: 0 }
        }}>
            <Grid item xs={12} md={4} container direction={'column'} sx={{
                order: { xs: 2, md: 1 },
                textAlign: { xs: 'center', md: 'left' }
            }}>
                <Typography 
                    fontFamily={"Lava Telugu"} 
                    sx={{ 
                        fontSize: { xs: '36px', sm: '50px', md: '64px' },
                        fontWeight: 700,
                        fontStyle: "normal",
                        color: 'white',
                        py: { xs: 2, md: 3 },
                        textShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
                        lineHeight: '100%',
                        position: 'relative',
                        backgroundImage: `url(${brush})`,
                        backgroundSize: { xs: '200px', md: '270px' },
                        backgroundPosition: { xs: 'center', md: 'left' },
                        backgroundRepeat: 'no-repeat',
                        backgroundPositionY: 75
                    }}
                >
                    {t('Introduction.title')}
                </Typography>

                <Typography 
                    color='#4E433C' 
                    sx={{
                        fontSize: { xs: '14px', md: '20px' },
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: '128%',
                        mb: { xs: 3, md: 0 }
                    }}
                >
                    {t('Introduction.text')}
                </Typography>

                <Grid item container 
                    sx={{
                        paddingTop: { xs: 4, md: 10 },
                        justifyContent: { xs: 'center', md: 'flex-start' }
                    }} 
                    spacing={2} 
                    direction={'column'}
                >
                    <Grid item>
                        <Link href="https://www.apple.com/hk/app-store/">
                            <img
                                src={AppStoreLogo}
                                alt="AppStore"
                                style={{
                                    width: isMobile ? '200px' : '50%',
                                    height: 'auto'
                                }}
                            />
                        </Link>
                    </Grid>
                    <Grid item>
                        <Link href='https://play.google.com/store/games'>
                            <img
                                src={GooglePlayLogo}
                                alt="GooglePlay"
                                style={{
                                    width: isMobile ? '200px' : '50%',
                                    height: 'auto'
                                }}
                            />
                        </Link>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} md={8} sx={{
                order: { xs: 1, md: 2 },
                mb: { xs: 4, md: 0 }
            }}>
                <img
                    src={image_Intro}
                    alt="imag_Intro"
                    style={{
                        width: '100%',
                        objectFit: 'contain',
                        maxWidth: isMobile ? '300px' : 'none',
                        display: 'block',
                        margin: isMobile ? '0 auto' : 'initial'
                    }}
                />
            </Grid>
        </Grid>
    );
}